@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@800&display=swap');

.nav-bar {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: top 0.3s;
    position: sticky;
    top: 0;

    // BACKGROUND CHANGE

    background: red;

    // 

    
    height: 10vh;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    z-index: 6;
    display: flex;
    align-items: center;
    .menu {
        z-index: 5;
        animation: fadeIn 0.5s linear;
        margin-right: 18px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        .line1,
        .line2,
        .line3 {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: whitesmoke;
            margin: 6px 0;
            border-radius: 2px;
        }
        .line1out {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: whitesmoke;
            margin: 6px 0;
            border-radius: 2px;
            -webkit-transform: rotate(-45deg) translate(-9px, 6px);
            transform: rotate(-45deg) translate(-9px, 6px);
        }
        .line2out {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: whitesmoke;
            margin: 6px 0;
            border-radius: 2px;
            opacity: 0;
        }
        .line3out {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: whitesmoke;
            margin: 6px 0;
            border-radius: 2px;
            -webkit-transform: rotate(45deg) translate(-8px, -8px);
            transform: rotate(45deg) translate(-8px, -8px);
        }
    }
    .nav-list {
        font-family: Impacto, Impact, sans-serif;
        animation: fadeIn 0.5s linear;
        width: 50%;
        position: relative;
        margin: 0 13px 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        padding: 0;
        height: 100%;
        li {
            width: 25%;
            a {
                font-size: 17px;
                color: whitesmoke;
                text-decoration: none;
                transition: 0.15s;
                text-shadow: 0.1px 0.1px 2px rgba(0,0,0,0.2);
                &:hover {
                    transition:  0.15s;
                    cursor: pointer;
                    text-shadow: 0.1px 0.1px 7px rgba(0,0,0,0.5);
                }
            }
            text-align: center;
            list-style-type: none;
            display: inline;
        }
    }
}

// Mobile Styles
@media only screen and (max-width: 960px) {
    .nav-list {
        display: none !important;
    }
    .sidebarOut {
        background-color: red;
    }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
    .menu {
        display: none !important;
    }
    .sidebarOut {
        background-color: black;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}