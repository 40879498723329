
@font-face {
  font-family: 'MyWebFont';
  src:  url('../../assets/Anton/Anton-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}
.app {
  position: relative;
  transition: 0.4 ease;
  overflow: hidden;
}

main {
  min-height: 80vh;
  padding: 25px;
  position: relative;
  top: 30px;
  margin-bottom: 40px;
}

.search-box {
  -webkit-box-shadow: 15px 24px 86px -46px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 15px 24px 86px -46px rgba(0, 0, 0, 0.52);
  box-shadow: 15px 24px 86px -46px rgba(0, 0, 0, 0.52);
  background-color: white;
  position: relative;
  border-radius: 5px;
  width: 40%;
  height: 50px;
  padding-top: 13px;
  margin-left: auto;
  margin-right: auto;
  #location {
    display: inline-block;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 12px;
    padding-top: 2px;
    &:hover {
      cursor: pointer;
    }
  }
  #search {
    display: inline-block;
    height: 30px;
    width: 30px;
    position: absolute;
    right: -40px;
    padding-top: 2px;
    &:hover {
      cursor: pointer;
    }
  }
}

.search-box .search-bar {
  display: block;
  width: 90%;

  background: none;
  border: none;
  outline: none;

  color: #313131;
  font-size: 20px;

  transition: 0.4s ease;
}

.location-box .location {
  display: block;
  color: black;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  font-family: 'MyWebFont';
  margin-bottom: 15px;
  margin-top: 40px;
}

.location-box .date {
  color: black;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  font-family: monospace;
}

.weather-box {
  text-align: center;
}

.weather-box .temp {
  margin: 30px auto;
  background-color: red;
  border-radius: 7px;
  width: 26%;
  padding: 20px;
  text-shadow: 4px 4px 0px rgba(0,0,0,0.2);
  color: whitesmoke;
  font-size: 500%;
  font-weight: 900;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);   
}

.weather-box .weather {
  color: black;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'MyWebFont';
  letter-spacing: 1.2px;

}
@keyframes jiggle {
  0% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }
}

#sperm {
  animation: jiggle 1s infinite;

}

@media only screen and (max-width: 960px) {
  .search-box {
    width: 95%;
    position: relative;
    right: 20px;
  }
  .storeButton {
    margin: 10px;
  }
  .weather-box .temp {
    position: relative;
    margin: 30px auto;
    background-color: red;
    border-radius: 7px;
    width: 80%;

    color: whitesmoke;
    font-size: 90px;
    font-weight: 900;
    text-align: center;
  }
  #search {
    display: inline-block;
    height: 30px;
    width: 30px;
    position: absolute;
    padding-top: 2px;
    &:hover {
      cursor: pointer;
    }
  }
  #sperm {
    width: 80vw;
  } 
  #head {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.lds-facebook {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 100px auto;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: red;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  100% {
    top: 24px;
    height: 32px;
  }
}


h1 {
  margin-top: 60px;
}



.storeButton {
  margin: 10px;
}

#price { 
  margin-bottom: 50px;
}
