
@font-face {
    font-family: 'MyWebFont';
    src:  url('../../assets/Anton/Anton-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  }

h4,h3 {
    text-align: left;
}
  .skill-container {
    width: 75%;
    margin-top: 200px;
    margin-bottom: 160px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding-top: 30px;
    ul li {
        display: inline-block;
        line-height: 17px;
        width: 25%;
        padding: 0.75rem 0;
        text-decoration: none;
        color: rgb(70, 48, 48);
        text-align: center;
        font-family: monospace;
        font-size: 20px;
        font-weight: bold;
        .mobile {
            display: none;
        }
        &:hover {
            cursor: pointer;
            color: red;

        }
        &:target {
            color: red;
        }
    }


    ul, div {
        width: 100%;
        margin: 50px auto;
        position: relative;
        h3 {
            margin-bottom: 0;
            font-size: 18px;
            margin-top: 80px;
            font-family: monospace;
        }
        h4{
            font-family: 'MyWebFont';
            font-size: 50px;
            margin-top: 20px;
            width: 400px;
            letter-spacing: 1.2px;;
        }
    }
    h1 {
        width: 100%;
        margin: 10px auto;
        font-family: Impacto, Impact, sans-serif;
        font-size: 60px;
        color: lightgray;
    }
    h2 {
        text-align: center;
        width: 100%;
        margin: 10px auto;
        font-family: monospace;
        font-size: 20px;
    }
    .link {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 56%;
        top: 5px;
        transition: 0.7;
        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition: 0.7;
        }
    }

    .long {
        background: lightgrey;
        position: relative;
        bottom: 11px;
        height: 0.1rem;
        border: none;
        z-index: -1;
    }
}
.zero{
    height: 0.25rem;
    width: 25%;
    margin: 0;
    background: red;
    border: none;
    transition: 0.3s ease;
    border-radius: 2px;
    margin-left: 0;
}
.one {
    height: 0.25rem;
    width: 25%;
    margin: 0;
    background: red;
    border: none;
    transition: 0.3s ease;
    border-radius: 2px;
    margin-left: 25%;
}
.two {
    height: 0.25rem;
    width: 25%;
    margin: 0;
    background: red;
    border: none;
    transition: 0.3s ease;
    border-radius: 2px;
    margin-left: 50%;
}

.three {
    height: 0.25rem;
    width: 25%;
    margin: 0;
    background: red;
    border: none;
    transition: 0.3s ease;
    border-radius: 2px;
    margin-left: 75%;
}
.four {
    height: 0.25rem;
    width: 20%;
    margin: 0;
    background: red;
    border: none;
    transition: 0.3s ease;
    border-radius: 2px;
    margin-left: 100%;
}

@media only screen and (max-width: 960px) {
    .skill-container {
        width: 100%;
        margin: 100px auto;
        ul {
            width: 100%;
            li {
                font-size: 15px;
                .mobile {
                    display: block;
                }
                .highfan {
                    display: none;
                }
            }
        }
        div {
            width: 100%;
            position: relative;
            bottom: 40px;
            h1 {
                margin-top: 0px;
                font-size: 70px;
                text-align: center;
            }
            h3 {
                margin-bottom: 0;
                font-size: 14px;
                margin-top: 90px;
                margin-left: 10px;
                font-family: monospace;
            }
            h4{
                font-size: 42px;
                margin-left: 10px;
                margin-top: 20px;
                width: 80vw;
                margin-right: 0px;
            }
        }
    }
}

