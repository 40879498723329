h1 {
  font-family: 'MyWebFont';
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
}
input {
  width: 30%;
}

label {
  padding-right: 10px;
}

* {
  text-align: center;
}

input {
  border-bottom: 0px !important;
}



input[type="range"] {
  width: 300px;
  margin: 18px 0;
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

//input[type="range"] + label {
//  background-color: #fff;
//  position: absolute;
//  top: -25px;
//  left: 110px;
//  width: 80px;
//  padding: 5px 0;
//  text-align: center;
//  border-radius: 4px;
//  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
//}

/* Chrome & Safari */
input[type="range"]::-webkit-slider-runnable-track {
  background: red;
  border-radius: 4px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid black;
  margin-top: -7px;
  cursor: pointer;



}

/* Firefox */
input[type="range"]::-moz-range-track {
  background: purple;
  border-radius: 4px;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid purple;
  margin-top: -7px;
  cursor: pointer;
}

/* IE */
input[type="range"]::-ms-track {
  background: purple;
  border-radius: 4px;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid purple;
  margin-top: -7px;
  cursor: pointer;
}
.metronome-container {
  width: 100%;
  justify-content: center;
  display: flex;
}