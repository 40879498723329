.Skills {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    padding: 0;
    height: 60px;
    img {
        margin: auto 8px;
        width: 35px;
    }
    .csslogo {
        width: 27.5px;
    }
}

@media only screen and (max-width: 961px) {
    .Skills {
        img {
            width: 20px;
            margin: auto 3px;
        }
        .csslogo {
            width: 16px;
        }
    }
}
