@font-face {
    font-family: "MyWebFont";
    src: url("../../assets/Anton/Anton-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

.sidebarIn {
    -webkit-box-shadow: -9px 2px 25px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -9px 2px 25px -10px rgba(0, 0, 0, 0.75);
    box-shadow: -9px 2px 25px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-around;
    background: rgb(237,237,237);
    background: -moz-linear-gradient(141deg, rgba(237,237,237,1) 0%, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(141deg, rgba(237,237,237,1) 0%, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(141deg, rgba(237,237,237,1) 0%, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ededed",endColorstr="#ffffff",GradientType=1);
    border-left: 4px black solid;
    width: 60vw;
    height: 100vh;
    position: fixed;
    right: -61vw;
    top: 0;
    z-index: 7;
    transition: 0.4s;
    transform: translateX(60vw);
    .menu {
        display: none;
    }
    ul {
        padding: 0;
        display: flex;
        height: 30vh;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top: 15vh;
        width: 100%;
    }
    .sideline {
        transition: 0.8s;
        width: 15%;
        height: 2px;
        background: black;
        margin: 2px auto;
        border-radius: 4px;
    }
    li {
        opacity: 0;
        text-align: center;
        list-style-type: none;
        height: 30px;
        transition: 1s;
        line-height: 30px;
        a {
            position: relative;
            font-family: 'MyWebFont';
            letter-spacing: 0.5px;
            font-size: 1.8em;
            color: black;
            text-decoration: none;
            &:hover {
                cursor: pointer;
                font-size: 1.85em;
            }
        }
    }
}
.sidebarOut {
    -webkit-box-shadow: -9px 2px 25px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -9px 2px 25px -10px rgba(0, 0, 0, 0.75);
    box-shadow: -9px 2px 25px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-around;
    background: rgb(237,237,237);
    background: -moz-linear-gradient(141deg, rgba(237,237,237,1) 0%, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(141deg, rgba(237,237,237,1) 0%, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(141deg, rgba(237,237,237,1) 0%, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ededed",endColorstr="#ffffff",GradientType=1);
    border-left: 4px black solid;
    width: 60vw;
    height: 100vh;
    position: fixed;
    right: -61vw;
    top: 0;
    z-index: 7;
    transition: 0.4s;
    transform: translateX(-60vw);
    .menu {
        z-index: 5;
        animation: fadeIn 0.5s linear;
        margin-right: 18px;
        cursor: pointer;
        position: fixed;
        top: calc(5vh - 17.5px);
        right: 12px;
        .line1,
        .line2,
        .line3 {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: whitesmoke;
            margin: 6px 0;
            border-radius: 2px;
        }
        .line1out {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: red;
            margin: 6px 0;
            border-radius: 2px;
            -webkit-transform: rotate(-45deg) translate(-9px, 6px);
            transform: rotate(-45deg) translate(-9px, 6px);
        }
        .line2out {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: red;
            margin: 6px 0;
            border-radius: 2px;
            opacity: 0;
        }
        .line3out {
            transition: 0.4s;
            width: 35px;
            height: 5px;
            background-color: red;
            margin: 6px 0;
            border-radius: 2px;
            -webkit-transform: rotate(45deg) translate(-8px, -8px);
            transform: rotate(45deg) translate(-8px, -8px);
        }
    }
    ul {
        padding: 0;
        display: flex;
        height: 30vh;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top: 15vh;
        width: 100%;
    }
    .sideline {
        transition: 0.8s;
        width: 45%;
        height: 2px;
        background: red;
        margin: 5px auto;
        border-radius: 4px;
    }
    li {
        opacity: 1;
        transition: 1s;
        text-align: center;
        list-style-type: none;
        height: 30px;
        line-height: 30px;
        a {
            position: relative;
            font-size: 1.8em;
            color: black;
            text-decoration: none;
            font-family: 'MyWebFont';
            letter-spacing: 0.5px;
            &:hover {
                cursor: pointer;
                font-size: 1.85em;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
    .sidebarIn {
        display: none;
    }
    .sidebarOut {
        display: none;
    }
}

.social-container {
    position: absolute;
    bottom: 17vh;
    width: 45%;
    display: flex;
    justify-content: space-between;
    a {
        margin: 0;
        padding: 0;
        img {
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
        }
    }
}

