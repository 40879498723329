

$base-color: green;

body {
    background:whitesmoke;
}

#app {
    min-height: 100vh;
}

#experience {
    height: 70vh;
}
html {
    scroll-behavior: smooth;
}
.container {
    width: 75%;
    margin: 0 auto;
    overflow: auto;
    min-height: 90vh;
}


// Mobile Styles
@media only screen and (max-width: 960px) {
    .container {
        width: 100%;
    }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
    .container {
        width: 75%;
    }
}

.designed {
    position: relative;
    font-family: monospace;
    font-size: 13px;
    color: rgb(0, 1, 0);
    width: 15%;
    margin: 2px auto;
    text-align: center;
}

@media only screen and (max-width: 960px) {
 
    .designed {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .demo {
        .main-title {
            font-size: 3em;
        }
    }
}


.form {
    position: relative;
    height: 40px;
    width: 300px;
    margin: 0 auto;
    text-align: left;
    .testinput {
     width: 100%;
     height: 100%;
     text-align: left;
     font-size: 20px;
     padding-top: 10px;
     border: 0;
     padding-left: 2px;
          &:focus + .testlabel .testspan {
            bottom: 21px;
            color: red;
        }
        &:focus + .testlabel::after {
            width: 100%;
            transition: all 0.5s ease;
        }
        &:valid + .testlabel .testspan {
            bottom: 21px;
            color: red;
        }
        &:valid + .testlabel::after {
            width: 100%;
            transition: all 0.5s ease;
        }
    }

    .testlabel {
        width: 100%;
        position: absolute;
        left: 2px;
        bottom: -4px;
        border-bottom: 2px black solid;
        pointer-events: none;
        transition: all 0.5s ease;
        &::after{
            content: '';
            height: 3px;
            background: red;
            width: 0;
            display: block;
            position: relative;
            top: 2.2px;
            transition: all 0.5s ease;
            
        }
   
        .testspan {
         position: absolute;
         bottom: 3.5px;
         transition: 0.5s;
         font-size: 13px;
        }
    }
}


.switch {
    position: absolute;
    top: 15px;
    left: 50px;
    z-index: 7;
    width: 65px;
    height: 36px;
    background: whitesmoke;
    border-radius: 34px;
    &:hover {
        cursor: pointer;
    }
    &:focus {
        background: red;
    }
    input {
        pointer-events: auto;
        width: 100px;
        height: 100%;
        display: none;

        &:checked + .slider {
            transition: 0.3s;
            left: 32px;
        }
    }
    .slider {
        cursor: pointer;
        pointer-events: auto;
        box-sizing: border-box;
        transition: 0.3s;
        position: absolute;
        left: 4px;
        top: 4px;
        display: block;
        width: 28px;
        background-color: red;
        height: 28px;
        border-radius: 50%;
    }
}

.checkbox {
    width: 100px;
    height: 100%;
    display: none !important;
}

.backbutton {
    display: block;
    position: fixed;
    top: 100px;
    width: 30px;
    height: 30px;
    img {
        width: 100%;
        height: 100%;
    }
    &:hover {
        cursor: pointer;
    }
    // &::after {
    //     content: 'BACK';
    //     display: block;
    //     height: 100%;
    //     width: 100%;
    //     color: red;
    //     font-family: monospace;
    //     font-weight: bold;
    //     font-size: 15px;
    // }
}


