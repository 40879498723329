.social {
    position: fixed;
    left: 40px;
    bottom: 0px;
    width: 35px;
    .logo-container {
        
        a {
            img {
                width: 21px;
                height: 21px;
                position: relative;
                margin: 19.5px auto;
                // left: 8px;
                bottom:  26px;
                transition: .25s;
                &:hover {
                    transition: .25s;
                    transform: scale(1.1);
                }
            }
        }
        &::after {
            content: "";
            display: block;
            width: 2px;
            height: 90px;
            background-color: black;
            margin: 0px auto;
            position: relative;
            border-radius: 2px;
        }
    }
}


@media only screen and (max-width: 960px)  {
    .social {
        display: none;
    }
}