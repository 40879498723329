@font-face {
    font-family: 'MyWebFont';
    src:  url('../../assets/Anton/Anton-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  }

.tiktaktoe {
    position: relative;
    height: 100vh;
    width: 75vw;
    h1 {
        text-align: center;
        font-size: 50px;
        font-family: 'MyWebFont';
        letter-spacing: 1.2px;
    }
    .restart {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        margin: 20px auto;
        width: 120px;
        display: block;
        border: 3px solid red;
        font-family: monospace;
        background-color: whitesmoke;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        &:hover {
            background: white;
            cursor: pointer;
        }
        &:focus {
            outline: 0;
        }
    }
    .board {
        height: 25vw;
        width: 25vw;
        margin: 10px auto;     
        display: flex;
        flex-wrap: wrap;
        position: relative;
        button {
            &:focus {
                outline: 0;
            }

            .click {
                    position: absolute;
                    width: 100%;
                    height: 100% ;
            }
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            width: 33.3%;
            height: 33.3%;
            background: red;
            border: 1px whitesmoke solid;
            color: whitesmoke;
            font-size: 60px;
            font-family: 'MyWebFont';
            letter-spacing: 1.2px;
            position: relative;
            border-radius: 6px;
            text-shadow: 4px 4px 0px rgba(0,0,0,0.2);
            &:hover {
                cursor: pointer;
            }
        }
        .winner {
            
text-shadow: 4px 4px 0px rgba(0,0,0,0.2);
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: red;
            color: white;
            text-align: center;
            font-size: 40px;
            align-items: center;
            display: flex;
            justify-content: center;
            font-family: 'MyWebFont';
            letter-spacing: 1.2px;
            border-radius: 8px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);        }
    }
}

@media only screen and (max-width: 960px) {
    .tiktaktoe {
        position: relative;
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        h1 {
            text-align: center;
            font-size: 50px;
            font-family: 'MyWebFont';
        }
        .restart {
            font-size: 20px;
            text-align: center;
            font-weight: bold;
            margin: 20px auto;
            width: 120px;
            display: block;
            border: 3px solid red;
            font-family: monospace;
            line-height: 30px;
            &:hover {
                background: white;
                cursor: pointer;
            }
        }
        .board {
            text-align: center;
            width: 80vw;
            height: 80vw;
            margin: 40px auto;     
            display: flex;
            flex-wrap: wrap;
            button {
                text-align: center;
                width: 33.3%;
                height: 33.3%;
                background: red;
                border: 1px whitesmoke solid;
                color: whitesmoke;
                font-size: 60px;
                font-family: 'MyWebFont';
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

