.email {
    position: fixed;
    right: 40px;
    bottom: 90px;
    width: 35px;
    display: flex;
    flex-direction: column;
    writing-mode: vertical-rl;
    align-items: center;
    a {
        font-size: 15px;
        color: black;
        font-family: monospace;
        transition: 0.25s;
        text-decoration: none;
        padding: 10px;
        display: inline-block;
        position: relative;
        bottom: 37px;
        &:hover {
            color: red;
            transition: 0.25s;
            transform: translateY(-3px);
        }
        &:hover::after {
            transition: 0.25s;
            transform: translateY(3px);
        }
        &::after {
            content: "";
            display: block;
            width: 2px;
            height: 120px;
            background-color: black;
            margin: 0px auto;
            position: relative;
            left: 8px;
            top: 200px;
            border-radius: 2px;
            transition: 0.25s;
        }
    }
}

@media only screen and (max-width: 960px)  {
    .email {
        display: none;
    }
}