@font-face {
    font-family: "MyWebFont";
    src: url("../../assets/Anton/Anton-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}
.metronome {
    margin-top: 100px;
    min-height: 70vh;
    .add-class {
        .class-add-form {
            .input-container {
                display: flex;
                justify-content: center;
                .class-input {
                    height: 50px;
                    background: white;
                    border-radius: 5px;
                    border: none;
                    font-size: 20px;
                    display: inline-block;
                    width: auto;
                }
                .class-add {
                    z-index: 2;
                    position: relative;
                    left: 3px;
                    top: 7px;
                    width: 30px;
                    border: none;
                    font-size: 40px;
                    color: red;
                    display: inline-block;
                    font-weight: normal;
                    z-index: 1;
                    &:hover {
                        font-weight: bold;
                        transition: 0.1s;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .modal-container {
        
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        .modal-back {
            position: fixed;
            left: 0;
            top: -10vh;
            width: 100vw;
            height: 110vh;
            background: rgba(0, 0, 0, 0.329);
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(12px);
        }
        .modal {
            z-index: 5;
            animation: appear 0.3s forwards;
            left: 25%;
            position: fixed;
            width: 50%;
            min-height: 50%;
            background-color: whitesmoke;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            border-radius: 5px;
            text-align: center;
            input {
                font-family: "MyWebFont";
                background-color: red;
                text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
                text-align: center;
                color: white;
                width: 100%;
                height: 50px;
                position: relative;
                top: 10px;
                margin: 10px auto !important;
                letter-spacing: 1px;
                cursor: pointer;
            }
            .closemodal {
                border: none;
                background-color: whitesmoke;
                position: absolute;
                font-size: 50px;
                right: 20px;
                top: 5px;
                font-weight: bolder;
                font-family: monospace;
                transition: 0.1s;
                &:hover {
                    cursor: pointer;
                    color: red;
                    transition: 0.1s;
                }
            }
            .grade-container {
                display: flex;
                flex-wrap: wrap;
                width: 80%;
                margin: 80px auto;
                display: flex;
                justify-content: center;
                .grade {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    list-style: none;
                    margin: 10px 10px;
                    background-color: red;
                    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
                    width: 50px;
                    height: 50px;
                    font-size: 30px;
                    color: white;
                    font-family: "mywebfont";
                    align-items: center;
                    border-radius: 5px;
                    transition: 0.2s;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                    .delete-grade {
                        width: 45%;
                        height: 45%;
                        background-color: whitesmoke;
                        position: absolute;
                        border: 1px black solid;
                        border-radius: 5px;
                        right: -7px;
                        top: -10px;
                        font-family: Arial, Helvetica, sans-serif;
                        color: black;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 0.2s;
                        &:hover {
                            border: 2px black solid;
                            font-weight: bold;
                            cursor: pointer;
                            transition: 0.2s;
                        }
                    }
                }
            }
        }
    }
    .overall {
        text-align: center;
        font-size: 20px;
        font-family: monospace;
        margin-bottom: 100px;
        &::after {
            content: "";
            height: 2px;
            width: 100%;
            background-color: lightgray;
            display: block;
            position: relative;
            top: 50px;
        }
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            margin: 10px auto;
            height: 50px;
            color: white;
            background-color: red;
            text-shadow: 2px 2px 0px rgba(0,0,0,0.2);
            padding: 0px;
            border-radius: 5px;
            font-family: "MyWebFont";
            letter-spacing: 1px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
    }

    ul {
        width: 100%;
        display: flex;
        justify-content: center;
        list-style: none;
        flex-wrap: wrap;
        min-height: 100%;
        .subject-div {
            position: relative;
            background-color: white;
            border-radius: 10px;
            padding: 10px;
            min-width: 100px;
            height: 100px;
            margin-top: 20px;
            margin-bottom: 40px;
            margin-left: 30px;
            margin-right: 30px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            transition: 0.2s;
            z-index: 0;
            .delete-class {
                transition: 0.2s;
                width: 25%;
                height: 25%;
                background-color: whitesmoke;
                position: absolute;
                border: 1px black solid;
                border-radius: 5px;
                right: -5px;
                top: -5px;
                &:hover {
                    transition: 0.2s;
                    border: 2px black solid;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
            &:hover {
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                transition: 0.2s;
                cursor: pointer;
            }
            h1 {
                font-size: 15px;
                margin-bottom: 10px;
                text-align: center;
                text-transform: uppercase;
                font-family: monospace;
            }
            li {
                text-shadow: 3px 3px 0px rgba(0,0,0,0.2);
                margin: 0 auto;
                font-family: 'mywebfont';
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: red;
                height: 50px;
                width: 100%;
                cursor: pointer;
                color: white;
                border-radius: 5px;
                font-size: 30px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            }
        }
    }
}
@media only screen and (max-width: 960px) {
    .metronome {
        width: 95vw;
        margin: 50px auto;
        .modal-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            .modal-back {
                position: fixed;
                left: 0;
                top: -10vh;
                width: 100vw;
                height: 110vh;
                background: rgba(0, 0, 0, 0.329);
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                backdrop-filter: blur(12px);
            }
            .modal {
                overflow: scroll;
                animation: appear 0.2s forwards;
                left: 10%;
                top: 15%;
                position: fixed;
                width: 80% !important;
                height: 80% !important;
                background-color: whitesmoke;
                opacity: 1;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                border-radius: 5px;
                .grade-container {
                    .grade {
                        .delete-grade {
                            &:hover {
                                border: 1px black solid;
                                font-weight: normal;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .closemodal {
                    position: absolute;
                    font-size: 30px;
                    right: 20px;
                    top: 15px;
                    font-weight: bolder;
                    font-family: monospace;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        ul {
            justify-content: space-between;
            width: 85%;
            margin-left: auto;
            margin-right: auto;
            .subject-div {
                width: 30vw;
                margin-left: 0px;
                margin-right: 0px;
                position: relative;
                word-break: break-all;
                .subject {
                    position: absolute;
                    bottom: -20px;
                    width: 70%;
                    left: 20px;
                }
                &:hover {
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                }
                .delete-class {
                    &:hover {
                        border: 1px black solid;
                        font-weight: normal;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
//SHOWING MODAL ANIMATION
@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

label {
    color: grey;
    h1 {
        color: black;
        text-transform: uppercase;
    }
}
select {
    width: 50px;
    margin-left: 15px;
}

.no-classes {
    font-size: 40px;
    margin-top: 50px;
    width: 100%;
    text-align: center;
    font-family: "Mywebfont";
}
