@font-face {
    font-family: "MyWebFont";
    src: url("../../assets/Anton/Anton-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

.About {
    display: flex;
    &::after {
        content: "";
        display: block;
    }
    h1 {
        font-family: monospace;
        font-size: 18px;
        margin-bottom: 5px;
        text-align: left;
    }
    h2 {
        font-family: "Times New Roman", Times, serif;
        font-size: 60px;
        margin: 0;
        text-align: left;
    }

    .line {
        height: 3px;
        width: 47%;
        background-color: black;
        margin-top: 25px;
        transition: 0.5s;
        &:hover {
            background-color: red;
            width: 50%;
            transition: 0.5s;
        }
    }
    div {
        p {
            margin-top: 25px;
            width: 46%;
            font-family: monospace;
        }
    }
    .contact-me {
        margin-top: 20px;
        display: inline-block;
        line-height: 30px;
        border-radius: 2px;
        display: flex;
        font-family: "MyWebFont";
        width: 96px;
        height: 35px;
        align-items: center;
        justify-content: space-between;
        padding-left: 2px;
        padding-right: 2px;
        &:hover {
            cursor: pointer;
        }
        &:hover a img {
            transform: translateX(10px);
            transition: 0.3s;
        }
        a {
            width: 100%;
            height: 100%;
            margin: 0;
            text-decoration: none;
            color: black;
            .reachsvg {
                margin: 0;
                height: 50px;
                width: 50px;
                transition: 0.3s;
                position: relative;
                right: 4px;
            }
        }
    }
    img {
        width: 28%;
        height: 18vw;
        margin: 30px auto;
        z-index: -1;
    }
}

// Mobile Styles
@media only screen and (max-width: 960px) {
    .About {
        flex-direction: column;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 10vh;
        h1 {
            font-size: 15px;
            text-align: center !important;
        }
        h2 {
            font-size: 40px;
            text-align: center !important;

        }
        .line {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
            width: 60vw;
        }
        div {
            p {
                margin: auto;
                width: 100%;
            }
        }
        .contact-me {
            font-size: 15px;
            margin-top: 30px;
            width: 35%;
            line-height: 50px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            &:hover a img {
                transform: translateX(0px);
                transition: 0.3s;
            }
        }
        img {
            width: 200px;
            height: 176px;
        }
    }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
    .About {
        text-align: left;
        margin-top: 15vh;
        margin-left: 3vw;
    }
}
