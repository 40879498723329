@font-face {
    font-family: 'MyWebFont';
    src:  url('../../assets/Anton/Anton-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  }

.myprojects {
    padding-top: 12vh;
    text-align: center;
    font-family: monospace;
    font-size: 20px;
    margin-bottom: 40px;
    z-index: 4;
}
.sepLine {
    width: 75%;
    height: 1px;
    background: lightgray;
    margin: 2px auto;
    display: none;
    border-radius: 4px;
}
.project-list {
    position: relative;
    display: flex;
    justify-content: space-around;
    z-index: 2;
    flex-wrap: wrap;
    a {
        position: relative;
        text-decoration: none;
    }
}
.todo-list {
    z-index: 3;
    position: relative;
    margin: 0px auto;
    height: 22vw;
    width: 22vw;
    background-color: white;
    border-radius: 2.5px;
    color: black;
    text-align: center;
    line-height: 40px;
    font-family: 'MyWebFont';
    margin-bottom: 20px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    &:hover {
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        cursor: pointer;
    };
    span {
        display: none;
    }
    h1 {
        z-index: 4;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        text-align: center;
        width: 100%;
        letter-spacing: 1px;
    }

    .background-color {
        
        border-radius: 2.5px;
        position: absolute;

        //BACKGROUND COLOR
        background: red;

        width: 100%;
        height: 100%;
        transition: 0.3s;
        z-index: 5;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            background-repeat: repeat;
            height: 10px;
            background-size: 20px 20px;
        }
    }
    &:hover .background-color {
        height: 0;
        transition: 0.3s;
        z-index: 10;
    }
}
.todo-container {
    position: relative;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
ul {
    padding-left: 0;
}

input {
    background-color: transparent;
    border: none;
    outline-width: 0;
    text-align: center;
    display: inline-block;
    border-bottom: 1px solid #ccc;
    float: left;
    font-family: monospace;
    width: 88%;
    height: 35px;
    line-height: 35px;
    font-size: 30px;
    color: rgb(95, 94, 97);
    padding: 0 0 0 0;
    &::placeholder {
        color: lightgrey;
    }
}

#projects {
    margin-top: 140px;

    &::after {
        content: "";
        display: block;
    }
}

// RESPONSIVE !

@media only screen and (max-width: 960px) {
    .sepLine {
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    #projects {
        margin-top: 35px;
        .myprojects {
            margin-bottom: 60px;
        }
    }

    .project-list {
        flex-direction: column;
    }
    .todo-list {
        height: 80vw;
        width: 65vw;
        overflow: hidden;
        margin-bottom: 0px;
        &:hover .background-color {
            border-radius: 2.5px;
            position: absolute;
            background: whitesmoke;

            width: 150%;
            transform: rotate(-10deg);
            height: 40%;
            bottom: -50px;
            left: -50px;
            transition: 0.3s;
            z-index: 5;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        span {
            padding-top: 1px;
            display: block;
            width: 22%;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 17px;
            right: 17px;
            margin: 0;
            color: white;
            z-index: 10;
            background: red;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            font-size: 16px;
            font-weight: 500;
            font-family: monospace;
            font-weight: 900;
        }

//BACKGROUND CHANGE
 
// 

        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);


        h1 {
            top: 42%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            color: black;
            letter-spacing: 1px;
            z-index: 10;
        }
        .background-color {
            border-radius: 2.5px;
            position: absolute;
            background: whitesmoke;

            width: 150%;
            transform: rotate(-10deg);
            height: 40%;
            bottom: -50px;
            left: -50px;
            transition: 0.3s;
            z-index: 5;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

        }
    }
    .myprojects {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 30px;
        padding-top: 12vh;
    }
}






