.todo-app {
    min-height: 80vh;
    margin-top: 45px;
    .edit-container {
        position: fixed;
        left: 0;
        top: -10vh;
        width: 100vw;
        height: 110vh;
        background: rgba(0, 0, 0, 0.329);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(12px);
        .edit-form {
            animation: appear 0.3s forwards;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            justify-content: center;
            margin-right: auto;
            margin-left: auto;
            height: 250px;
            width: 400px;
            background: rgb(255, 255, 255);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            label {
                position: absolute;
                top: 20px;
            }
            .edit-change {
                padding-top: 10px;
                resize: none;
                font-size: 15px;
                height: 40%;
                width: 60%;
                background-color: whitesmoke;
                border: none;
                text-align: center;
                border-radius: 5px;
                &:focus {
                    outline: none;
                }
            }
            .edit-submit {
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                width: 30%;
                border: none;
                background: red;
                color: white;
                border-radius: 5px;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 20px;
                font-weight: bold;
                margin-top: 10px;
                position: absolute;
                bottom: 12px;
                transition: 0.3s;
                &:hover {
                    transition: 0.3s;
                    box-shadow: 2px 5px 7px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
                    cursor: pointer;
                }
            }
        }
    }
    h1 {
        font-family: "MyWebFont";
        letter-spacing: 1.2px;
    }
    ul {
        margin-left: auto;
        margin-left: auto;
        text-align: center;
        margin-top: 70px;

        li {
            word-break: break-all;
            min-height: 40px;
            font-weight: bold;
            font-family: monospace;
            border: 2px solid;
            border-radius: 3px;
            width: 50%;
            overflow: auto;
            margin: 5px auto;
            padding: 10px;
            list-style: none;
            background-color: whitesmoke;
            position: relative;
            transition: 0.2s;
            div {
                margin-bottom: 10px;
            }
            &:hover button {
                background-color: white;
            }
            &:hover {
                cursor: pointer;
                background-color: white;
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                width: 51%;
                transition: 0.2s;
            }

            .add-to-done {
                position: absolute;
                left: 4px;
                height: 22px;
                bottom: 4px;
                border: none;
                font-weight: bold;

                &:focus {
                    outline: 0px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .delete {
                position: absolute;
                right: 1px;
                height: 22px;
                bottom: 4px;
                border: none;
                font-weight: bold;
                &:focus {
                    outline: 0px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .edit {
                border: none;
                font-weight: bold;
                position: absolute;
                bottom: 4px;
                height: 19px;
                width: 19px;
                left: calc(50% - 12px);
                text-align: center;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    span {
        color: rgb(11, 88, 11);
        font-size: 30px;
    }

    .userinput {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .textinput {
        width: 30%;
        background-color: white;
        border: none;
        height: 45px;
        border-radius: 5px;
        font-size: 23px;
    }
    .addbutton {
        padding-bottom: 1px;
        font-family: monospace;
        font-size: 45px;
        height: 100%;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        color: red;
        border-radius: 2px;
        position: relative;
        right: 10px;
        &:hover {
            cursor: pointer;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: 960px) {
    .todo-app {
        .edit-container {
            .edit-form {
                width: 80%;
                height: 50%;
                .edit-change {
                    height: 60%;
                    width: 70%;
                }
            }
        }
        ul {
            li {
                width: 70%;
                &:hover {
                    background-color: whitesmoke;
                    border-color: black;
                    width: 70%;
                    box-shadow: none;
                }
                &:hover button {
                    background-color: whitesmoke;
                }
                .add-to-done {
                    color: rgb(11, 88, 11);
                }
                .delete {
                    color: red;
                }
                .edit {
                    color: rgb(255, 217, 0);
                }
            }
        }
    }

    .userinput {
        .addbutton {
            margin: 0;
            position: relative;
            left: 10px;
            &:hover {
                font-weight: normal;
            }
        }
        .textinput {
            width: 70%;
            margin-right: 5px;
            position: relative;
        }
    }
}

.done h1 {
    font-family: monospace;
}

p {
    width: 100%;
    text-align: center;
    font-family: monospace;
    margin-top: 40px;
    margin-bottom: 0;
}
